import React from "react";
import {useSelector} from "react-redux";

export default () => {
    const text = useSelector(state => state.content.FOOTER);

    return (
        <footer className="flex flex-column">
            <div className="row">
                <div className="col-6">
                    <h4>Site</h4>
                    <ul className="list-unstyled discret-link">
                        <li><a href="#accueil">Accueil</a></li>
                        <li><a href="#musique">Musique</a></li>
                        <li><a href="#biographie">Biographie</a></li>
                        <li><a href="#news">News</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
                <div className="col-6">
                    <h4>Stef Sirrah</h4>
                    <p dangerouslySetInnerHTML={{__html: text}} />
                </div>
            </div>
            <p className="text-center pt-5">
                Made with <strong>♡ </strong>
                by <strong><a href="https://pragm.agency" rel="nofollow" target="_blank">Pragm.agency</a></strong>
            </p>
        </footer>
    )
}
