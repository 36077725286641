import React from "react";
import logo from "../assets/images/logo.png";
import Icon from '@mdi/react';
import { mdiFacebook, mdiInstagram, mdiTwitter, mdiSpotify, mdiSoundcloud } from '@mdi/js';

const SocialLinks = ({classes, style = {}}) => (
    <div className={`even-width ${classes}`} style={style}>
        <a target="_blank" rel="nofollow" href="https://www.facebook.com/StefSirrah" className="ml-3" title="Facebook"><Icon path={mdiFacebook} size={1} color="#d1c3b2"/></a>
        <a target="_blank" rel="nofollow" href="https://www.instagram.com/stefsirrah/?hl=fr" className="ml-3" title="Instagram"><Icon path={mdiInstagram} size={1} color="#d1c3b2"/></a>
        <a target="_blank" rel="nofollow" href="https://twitter.com/StefSirrah" className="ml-3" title="Twitter"><Icon path={mdiTwitter} size={1} color="#d1c3b2"/></a>
        <a target="_blank" rel="nofollow" href="https://open.spotify.com/artist/61QJpiutFjDCLv4OTElieY?si=XHxc4bAnQN-hI-g9Y4ONqg" className="ml-3" title="Spotify"><Icon path={mdiSpotify} size={1} color="#d1c3b2"/></a>
        <a target="_blank" rel="nofollow" href="https://soundcloud.com/stef-sirrah" className="ml-3" title="Soundcloud"><Icon path={mdiSoundcloud} size={1} color="#d1c3b2"/></a>
    </div>
)

const NavLinks = ({classes, id}) => (
    <div className={classes} id={id}>
        <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
                <a className="nav-link" href="#accueil">Accueil</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#musique">Musique</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#biographie">Biographie</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#news">News</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#contact">Contact</a>
            </li>
        </ul>
        {'navbarCollapse' === id && <SocialLinks classes="d-flex flex-column justify-content-between w-auto" style={{lineHeight: 'normal', borderBottom: 'none', height: 'auto'}} />}
    </div>
);

export default () => {
    return (
        <header>
            <nav className="navbar navbar-expand-md navbar-dark">
                <NavLinks classes="d-none d-md-block even-width" />
                <div className="d-block d-md-none" />
                <img src={logo} alt="Stef Sirrah" />
                <SocialLinks classes="d-none d-md-flex justify-content-end" />
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <NavLinks classes="collapse navbar-collapse d-md-none justify-content-between align-items-stretch" id="navbarCollapse" />
            </nav>
        </header>
    )
}
