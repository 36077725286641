import React, {useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(() => ({
    col: {
        "&:hover": {
            zIndex: 9,
        },
    },
    tile: {
        cursor: 'pointer',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'all 200ms',
        "&:hover": {
            zIndex: 9,
            transform: 'scale(1.05)',
            left: '-2.5%',
        },
        '& span': {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            padding: 16,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            fontSize: 28,
        }
    },
}));

export default ({article, setModalOpened}) => {
    const classes = useStyles(),
        ref = useRef(null),
        [height, setHeight] = useState(200);

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (null !== ref) {
                setHeight(ref.current.offsetWidth);
            }
        });
    }, []);

    useEffect(() => {
        if (null === ref) {
            return;
        }

        setHeight(ref.current.offsetWidth);
    }, [ref]);

    const style = useMemo(() => ({
        height,
        backgroundImage: `url(${apiUrl}${article.image})`,
    }), [height, article]);

    return (
        <div ref={ref} className={`${classes.col} col-12 col-sm-6 col-md-4 col-xl-3 p-0`} onClick={setModalOpened}>
            <div className={classes.tile} style={style}>
                <span>
                    {article.title}
                </span>
            </div>
        </div>
    );
};
