import React, {useEffect} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homepage from "./components/Homepage";
import ParallaxBackground from "./components/partials/ParallaxBackground";
import Biography from "./components/Biography";
import Introduction from "./components/Introduction";
import Music from "./components/Music";
import Contact from "./components/Contact";
import News from "./components/News";
import {useDispatch} from "react-redux";
import {getContent} from "./store/actions/main";
import Player from "./components/Player";

export default () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getContent());
    }, [dispatch]);

    return (
        <>
            <ParallaxBackground />
            <Header />

            <main role="main">
                <Homepage />
                <Introduction />
                <Music />
                <Biography />
                <News />
                <Contact />
                <Footer />
            </main>

            <Player />
        </>
    );
}
