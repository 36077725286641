import React from "react";
import stef from "../assets/images/stef.png";
import {useSelector} from "react-redux";

export default () => {
    const bio = useSelector(state => state.content.BIOGRAPHIE);

    return (
        <div className="main-part" id="biographie">
            <h2>Biographie</h2>
            <div className="row">
                <div className="col-sm-6 col-md-5" dangerouslySetInnerHTML={{__html: bio}} />
                <div className="d-none d-md-block col-md-2" />
                <div className="col-sm-6 col-md-5">
                    <img className="img-fluid" src={stef} alt="Stef Sirrah" />
                </div>
            </div>
        </div>
    )
}
