import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import mainApp from './reducers/main';

const api = process.env.REACT_APP_API_URL,
    store = createStore(
        mainApp,
        applyMiddleware(thunk.withExtraArgument({api}))
    );

export default store;
