export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';

export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM';
export const UPDATE_CONTACT_FORM_ERRORS = 'UPDATE_CONTACT_FORM_ERRORS';
export const CONTACT_FORM_SUBMITED = 'CONTACT_FORM_SUBMITED';

export const PLAYER_TOGGLE_PLAY_PAUSE = 'PLAYER_TOGGLE_PLAY_PAUSE';
export const PLAYER_SET_MUSIC_TO_PLAY = 'PLAYER_SET_MUSIC_TO_PLAY';
export const PLAYER_SET_NEXT_MUSIC = 'PLAYER_SET_NEXT_MUSIC';
export const PLAYER_START = 'PLAYER_START';

export const getContent = () => (dispatch, getState, {api}) => {
    fetch(`${api}api`)
        .then(response => response.json())
        .then(content => dispatch({type: GET_CONTENT_SUCCESS, content}));
}

export const updateContactForm = (name, value) => dispatch => {
    dispatch({type: UPDATE_CONTACT_FORM, name, value});
}

export const submitContactForm = () => (dispatch, getState, {api}) => {
    let errors = {};
    dispatch({type: UPDATE_CONTACT_FORM_ERRORS, errors});
    const form = getState().contactForm;

    if ('' === form.lastname.trim()) {
        errors['lastname'] = 'Le nom est obligatoire';
    }
    if ('' === form.firstname.trim()) {
        errors['firstname'] = 'Le prénom est obligatoire';
    }
    if ('' === form.email.trim()) {
        errors['email'] = 'L\'email est obligatoire';
    }
    if ('' === form.subject.trim()) {
        errors['subject'] = 'Le sujet est obligatoire';
    }
    if ('' === form.message.trim()) {
        errors['message'] = 'Le message est obligatoire';
    }

    if (Object.keys(errors).length > 0) {
        dispatch({type: UPDATE_CONTACT_FORM_ERRORS, errors});
        return;
    }

    window.grecaptcha.ready(function() {
        window.grecaptcha.execute('6LcLOVUaAAAAAJGc09ffpUOG694dC9oovcZySkV8', {action: 'submit'}).then(token => {
            fetch(`${api}mail`, {method: 'POST', body: JSON.stringify({...form, token})})
                .then(response => response.json())
                .then(content => dispatch({type: CONTACT_FORM_SUBMITED, content}));
        });
    });
}

export const togglePlayPause = force => ({type: PLAYER_TOGGLE_PLAY_PAUSE, force});

export const setMusicToPlay = music => ({type: PLAYER_SET_MUSIC_TO_PLAY, music});

export const nextMusic = () => ({type: PLAYER_SET_NEXT_MUSIC});

export const startPlaying = () => ({type: PLAYER_START});
