import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import NewsTile from "./partials/NewsTile";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    makeStyles,
    Slide,
    Typography
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {mdiClose, mdiArrowLeftThick, mdiArrowRightThick} from "@mdi/js";
import Icon from "@mdi/react";

const useStyles = makeStyles(theme => ({
    dialogContentImage: {
        '& img': {
            width: '100%',
            maxWidth: 250,
            float: 'left',
            marginRight: 16,
            marginBottom: 16,
        },
        ['@media (max-width:600px)']: {
            width: '100%',
            overflow: 'hidden',
            textAlign: 'center',
            '& img': {float: 'none'},
        },
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    paper: {
        backgroundColor: 'rgba(0, 0, 0, 0.70)',
        padding: 8,
        width: '80%',
        outline: 'none',
        '& h2': {margin: '0 8px 8px'},
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
    },
}));

const apiUrl = process.env.REACT_APP_API_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = ({children, onClose, ...other}) => {
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <Icon path={mdiClose} size={1} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

export default () => {
    const articles = useSelector(state => state.articles),
        classes = useStyles(),
        [modalOpened, setModalOpened] = useState(false),
        [currentArticle, setCurrentArticle] = useState(null),
        [articleIndex, setArticleIndex] = useState(null),
        openModalToArticle = article => {
            setCurrentArticle(article);
            setModalOpened(true);
        },
        goToNthArticle = index => () => {
            setModalOpened(false);
            setTimeout(() => {
                setCurrentArticle(articles[index]);
                setModalOpened(true);
            }, 300);
        };

    useEffect(() => {
        setArticleIndex(null !== currentArticle ? articles.findIndex(article => article === currentArticle) : null);
    }, [currentArticle, articles])

    return (
        <div className="main-part" id="news">
            <h2 className="text-right">News</h2>

            <div className="row justify-content-md-center">
                {articles.map(article => <NewsTile key={article.id} article={article} setModalOpened={() => openModalToArticle(article)} /> )}
            </div>

            <Dialog
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                PaperProps={{style: {backgroundColor: 'rgba(255, 255, 255, 0.95)'}}}
                TransitionComponent={Transition}
                open={modalOpened}
                scroll="body"
                onClose={() => setModalOpened(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
                fullWidth={true}
                maxWidth="sm"
            >
                {
                    null !== currentArticle ?
                        <>
                            <DialogTitle id="transition-modal-title" onClose={() => setModalOpened(false)}>
                                {currentArticle.title}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="transition-modal-description">
                                    <div className={classes.dialogContentImage}>
                                        <img src={`${apiUrl}${currentArticle.image}`} alt={currentArticle.title} />
                                    </div>
                                    <div id="article-content" dangerouslySetInnerHTML={{__html: currentArticle.content}} />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={goToNthArticle(articleIndex - 1)}
                                    color="primary"
                                    variant="outlined"
                                    style={{visibility: articleIndex > 0 ? 'visible' : 'hidden'}}
                                    startIcon={<Icon path={mdiArrowLeftThick} size={1} />}
                                >
                                    Précédente
                                </Button>
                                <Button onClick={() => setModalOpened(false)} color="default" variant="outlined">
                                    Fermer
                                </Button>
                                <Button
                                    onClick={goToNthArticle(articleIndex + 1)}
                                    color="primary"
                                    variant="outlined"
                                    style={{visibility: articleIndex + 1 < articles.length ? 'visible' : 'hidden'}}
                                    endIcon={<Icon path={mdiArrowRightThick} size={1} />}
                                >
                                    Suivante
                                </Button>
                            </DialogActions>
                        </> :
                        <div />
                }
            </Dialog>
        </div>
    )
}
