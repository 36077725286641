import React from "react";
import logo from "../../assets/images/logo.png";
import {Transition} from 'react-transition-group';

const duration = 750,
    defaultStyle = {transition: `opacity ${duration}ms ease-in-out`, opacity: 0},
    transitionStyles = {entering: {opacity: 1}, entered: {opacity: 1}, exiting: {opacity: 0}, exited: {opacity: 0}};

export default ({isIn}) => {
    return (
        <Transition in={!isIn} timeout={duration}>
            {state => state !== 'exited' && (
                <div
                    id="home-loader"
                    className="d-flex position-fixed justify-content-center align-items-center"
                    style={{...defaultStyle, ...transitionStyles[state]}}
                >
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <img src={logo} alt="F"/>
                </div>
            )}
        </Transition>
    )
}
