import React, {useMemo, useState} from "react";
import ProjectTile from "./partials/ProjectTile";
import {Dialog, makeStyles, Slide} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import {mdiPlayCircleOutline, mdiPauseCircleOutline} from "@mdi/js";
import Icon from "@mdi/react";
import {useDispatch, useSelector} from "react-redux";
import {setMusicToPlay, togglePlayPause} from "../store/actions/main";

const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(theme => ({
    paper: {
        padding: 8,
        color: theme.palette.light.main,
        '& img': {width: '100%'},
        '& h2': {margin: '16px 0'},
        '& ul': {
            listStyle: 'none',
            padding: 0,
            '& li': {cursor: 'pointer'}
        },
    },
    playIcon: {
        marginRight: 8,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default () => {
    const projects = useSelector(state => state.music),
        classes = useStyles(),
        [modalOpened, setModalOpened] = useState(null),
        player = useSelector(state => state.player),
        currentPlayingMusicId = useMemo(() => {
            if (false === player.playing || null === player.music) {
                return null;
            }

            return player.music.id;
        }, [player]),
        dispatch = useDispatch(),
        toggleMusic = music => () => {
            if (currentPlayingMusicId === music.id) {
                return dispatch(togglePlayPause(false));
            }

            dispatch(setMusicToPlay(music));
        };

    return (
        <div className="main-part" id="musique">
            <h2 className="text-right">Musique</h2>

            <div className="row justify-content-md-center">
                {projects.map(project => <ProjectTile key={project.id} project={project} setModalOpened={setModalOpened} />)}
            </div>

            <Dialog
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                PaperProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.70)'}}}
                TransitionComponent={Transition}
                open={null !== modalOpened}
                scroll="body"
                onClose={() => setModalOpened(null)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500}}
                fullWidth={true}
                maxWidth="xs"
            >
                {
                    null !== modalOpened ?
                        <div className={classes.paper}>
                            <img src={`${apiUrl}${modalOpened.image}`} alt={modalOpened.name} />
                            <h2 id="transition-modal-title">{modalOpened.name}</h2>
                            <ul>
                                {modalOpened.musics.map(music => <li key={music.id} onClick={toggleMusic(music)}>
                                    <Icon
                                        className={classes.playIcon}
                                        path={currentPlayingMusicId === music.id ? mdiPauseCircleOutline : mdiPlayCircleOutline}
                                        size={1}
                                        color="#d1c3b2"
                                    />
                                    {music.title}
                                </li>)}
                            </ul>
                        </div> :
                        <div />
                }
            </Dialog>
        </div>
    )
}
