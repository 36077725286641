import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {submitContactForm, updateContactForm} from "../store/actions/main";

export default () => {
    const form = useSelector(state => state.contactForm),
        errors = useSelector(state => state.contactFormErrors),
        sent = useSelector(state => state.contactFormSent),
        dispatch = useDispatch();

    const handleFormChange = event => {
        dispatch(updateContactForm(event.target.name, event.target.value));
    }

    const handleSubmit = event => {
        event.preventDefault();
        dispatch(submitContactForm());
    }

    return (
        <div className="main-part" id="contact">
            <div className="text-right">
                <h2>Contact</h2>
                <p>
                    <a href="mailto:stefsirrah@gmail.com">stefsirrah@gmail.com</a><br />
                </p>
            </div>
            <div className="row justify-content-center">
                <div className="col col-md-10 col-lg-8">
                    {
                        sent ?
                            <div className="alert alert-success" role="alert">
                                Votre message a été envoyé avec succès.
                            </div>
                            :
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input
                                            type="text"
                                            className={`form-control ${undefined === errors.lastname ? '' : 'is-invalid'}`}
                                            placeholder="Nom"
                                            value={form.lastname}
                                            name="lastname"
                                            onChange={handleFormChange}
                                        />
                                        {undefined !== errors.lastname && <div className="invalid-feedback">{errors.lastname}</div>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input
                                            type="text"
                                            className={`form-control ${undefined === errors.firstname ? '' : 'is-invalid'}`}
                                            placeholder="Prénom"
                                            value={form.firstname}
                                            name="firstname"
                                            onChange={handleFormChange}
                                        />
                                        {undefined !== errors.firstname && <div className="invalid-feedback">{errors.firstname}</div>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className={`form-control ${undefined === errors.email ? '' : 'is-invalid'}`}
                                        placeholder="Email"
                                        value={form.email}
                                        name="email"
                                        onChange={handleFormChange}
                                    />
                                    {undefined !== errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`form-control ${undefined === errors.type ? '' : 'is-invalid'}`}
                                        placeholder="Type de prestation"
                                        value={form.type}
                                        name="type"
                                        onChange={handleFormChange}
                                    />
                                    {undefined !== errors.type && <div className="invalid-feedback">{errors.type}</div>}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`form-control ${undefined === errors.subject ? '' : 'is-invalid'}`}
                                        placeholder="Sujet"
                                        value={form.subject}
                                        name="subject"
                                        onChange={handleFormChange}
                                    />
                                    {undefined !== errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
                                </div>
                                <div className="form-group">
                            <textarea
                                className={`form-control ${undefined === errors.message ? '' : 'is-invalid'}`}
                                placeholder="Message"
                                rows={5}
                                value={form.message}
                                name="message"
                                onChange={handleFormChange}
                            />
                                    {undefined !== errors.message && <div className="invalid-feedback">{errors.message}</div>}
                                </div>
                                <div className="form-group text-right">
                                    <button type="submit" className="btn btn-primary">ENVOYEZ</button>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    )
}
