import * as Actions from '../actions/main'

const defaultContactForm = {
    lastname: '',
    firstname: '',
    email: '',
    type: '',
    subject: '',
    message: '',
};

const initialState = {
    loaderHidden: false,
    music: [],
    articles: [],
    content: [],
    contactForm: defaultContactForm,
    contactFormErrors: {},
    contactFormSent: false,
    player: {
        playing: false,
        music: null,
    }
};

export default function mainApp(state = initialState, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case Actions.GET_CONTENT_SUCCESS:
            return {
                ...state,
                loaderHidden: true,
                ...action.content
            };

        case Actions.UPDATE_CONTACT_FORM:
            return {
                ...state,
                contactForm: {
                    ...state.contactForm,
                    [action.name]: action.value,
                },
            };

        case Actions.UPDATE_CONTACT_FORM_ERRORS:
            return {
                ...state,
                contactFormErrors: action.errors
            };

        case Actions.CONTACT_FORM_SUBMITED:
            return {
                ...state,
                contactForm: defaultContactForm,
                contactFormSent: true,
            };

        case Actions.PLAYER_TOGGLE_PLAY_PAUSE:
            return {
                ...state,
                player: {
                    ...state.player,
                    playing: undefined !== action.force ? action.force : !state.player.playing,
                },
            };

        case Actions.PLAYER_SET_MUSIC_TO_PLAY:
            return {
                ...state,
                player: {
                    ...state.player,
                    playing: true,
                    music: action.music
                },
            };

        case Actions.PLAYER_SET_NEXT_MUSIC:
            let musicToPlay = null;

            state.music.forEach(project => {
                if (null !== musicToPlay) {
                    return;
                }

                let foundMusic = false;

                project.musics.forEach(music => {
                    if (null !== musicToPlay) {
                        return;
                    }

                    if (foundMusic) {
                        musicToPlay = music;
                        return;
                    }

                    foundMusic = music.id === state.player.music.id;
                });
            });

            return {
                ...state,
                player: {
                    ...state.player,
                    music: musicToPlay,
                },
            };

        case Actions.PLAYER_START:
            let musicToPlayFirst = null;

            state.music.forEach(project => {
                if (null !== musicToPlayFirst) {
                    return;
                }

                project.musics.forEach(music => {
                    if (null !== musicToPlayFirst) {
                        return;
                    }

                    musicToPlayFirst = music;
                });
            });

            return {
                ...state,
                player: {
                    ...state.player,
                    playing: true,
                    music: musicToPlayFirst,
                },
            };

        default:
            return state;
    }
};
