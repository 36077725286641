import React from "react";
import {useSelector} from "react-redux";

export default () => {
    const citation = useSelector(state => state.content.CITATION);

    return (
        <div className="main-part" id="introduction">
            <p dangerouslySetInnerHTML={{__html: citation}} />
        </div>
    )
}
