import React, {useCallback, useEffect, useState} from "react";

export default () => {
    const [offsetX, setOffsetX] = useState(0),
        updateOffset = useCallback(() => {
            setOffsetX((window.scrollY / (document.body.clientHeight + window.innerHeight))*100);
        }, []);

    useEffect(() => {
        window.addEventListener('scroll', updateOffset)
    }, [updateOffset]);

    /* parallaxe sur les spaceEcouteurs */

    return (
        <div id="parallax-background" style={{left: `${offsetX}%`, zIndex: -1}}>
            <div id="pb-character">
                <div />
            </div>
            <div id="pb-lights" />
        </div>
    )
}
