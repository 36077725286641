import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactAudioPlayer from 'react-audio-player';
import {nextMusic, startPlaying, togglePlayPause} from "../store/actions/main";
import {makeStyles, Slider} from "@material-ui/core";
import {
    mdiPauseCircleOutline,
    mdiPlayCircleOutline,
    mdiVolumeHigh,
    mdiVolumeLow,
    mdiVolumeMedium,
    mdiVolumeOff
} from "@mdi/js";
import Icon from "@mdi/react";

const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(theme => ({
    player: {
        position: 'fixed',
        right: 0,
        top: '20%',
        zIndex: 9,
        backgroundColor: theme.palette.light.main,
        width: 35,
        height: 200,
        borderRadius: '18px 0 0 18px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '8px 0 16px'
    },
    playIcon: {
        cursor: 'pointer',
    },
    volumeIcon: {
        cursor: 'pointer',
        margin: '10px 0 20px',
    },
}));

export default () => {
    const player = useSelector(state => state.player),
        [ref, setRef] = useState(null),
        [volume, setVolume] = useState(100),
        classes = useStyles(),
        dispatch = useDispatch();

    useEffect(() => {
        if (null === ref || null === player.music) {
            return;
        }

        player.playing ? ref.audioEl.current.play() : ref.audioEl.current.pause();
    }, [ref, player.playing, player.music]);

    const volumeIcon = useMemo(() => {
        if (volume >= 75) {
            return mdiVolumeHigh;
        } else if (volume >= 25) {
            return mdiVolumeMedium;
        } else if (volume > 0) {
            return mdiVolumeLow;
        }

        return mdiVolumeOff;
    }, [volume]);

    const clickPlayPause = () => {
        if (player.playing || null !== player.music) {
            return dispatch(togglePlayPause());
        }

        dispatch(startPlaying());
    };

    return (
        <div className={classes.player}>
            <Icon
                className={classes.playIcon}
                path={player.playing ? mdiPauseCircleOutline : mdiPlayCircleOutline}
                size={1.5}
                color="#000000"
                onClick={clickPlayPause}
            />
            <Icon
                className={classes.volumeIcon}
                path={volumeIcon}
                size={1.5}
                color="#000000"
                onClick={() => setVolume(volume > 0 ? 0 : 100)}
            />
            <Slider
                orientation="vertical"
                value={volume}
                onChange={(e, newVolume) => setVolume(newVolume)}
                aria-labelledby="vertical-slider"
            />
            {
                null !== player.music &&
                <ReactAudioPlayer
                    src={`${apiUrl}${player.music.file}`}
                    autoPlay
                    ref={element => setRef(element)}
                    onEnded={() => dispatch(nextMusic())}
                    volume={volume/100}
                />
            }
        </div>
    )
}
