import React from "react";
import {connect} from "react-redux";
import Loader from "./partials/Loader";

const Homepage = ({loaderHidden}) => {
    return (
        <>
            <Loader isIn={loaderHidden} />
            <div id="accueil">
                <h1>Stef Sirrah</h1>
                <h2>Compositeur</h2>
            </div>
        </>
    )
}


const mapStateToProps = state => ({
    loaderHidden: state.loaderHidden,
});

export default connect(mapStateToProps)(Homepage);
